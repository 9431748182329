<template>
  <el-container>
    <el-row style="width: 100%">
      <el-col :span="24">
        <div class="cardTitle">标签列表</div>
        <el-button type="primary" @click="goAddTag" class="cardTitleBtn">添加标签</el-button>
      </el-col>
      <el-col :span="24">
        <el-table :data="tagList" style="width: 100%">
            <el-table-column prop="name" label="名称" sortable></el-table-column>
            <el-table-column fixed="right" label="操作">
                <template slot-scope="scope">
                <el-button @click="goEditTag(scope.row)" type="primary" size="mini">编辑</el-button>
                <el-button @click="onDelete(scope.row)" type="danger" size="mini">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
      </el-col>
    </el-row>

    <el-dialog title="添加标签" width="30%" :visible.sync="tagTypeVisible" >
        <el-form>
            <el-form-item label="标签名称">
                <el-input v-model="tagName" placeholder="请填写标签名称"></el-input>
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button @click="tagTypeVisible = false">取 消</el-button>
            <el-button type="primary" @click="addAndEditTag('add')">确 定</el-button>
        </div>
    </el-dialog>

    <el-dialog title="修改标签" width="30%" :visible.sync="editTagTypeVisible" >
        <el-form>
            <el-form-item label="标签名称">
                <el-input v-model="tag.name"></el-input>
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button @click="editTagTypeVisible = false">取 消</el-button>
            <el-button type="primary" @click="addAndEditTag('edit')">确 定</el-button>
        </div>
    </el-dialog>
  </el-container>
</template>

<script>
export default {
  data() {
    return {
      userInfo: {},
      tagList:[],
      tagTypeVisible: false,
      tagName: '',
      editTagTypeVisible: false,
      tag: {}
    };
  },
  mounted() {
    let self = this;
    self.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    self.getTagList()
  },
  methods: {
    // 获取便签列表
    getTagList() {
      let self = this;
      self.$axios
        .get("/api/class-tag/list", {
          headers: { token: self.userInfo.token },
        })
        .then(function (response) {
          self.tagList = response.data.data;
        })
        .catch(function (error) {
          self.$message({
            message: "标签列表获取失败："+error.response.data.message,
            type: "warning",
          });
        });
    },
    // 添加标签
    goAddTag() {
        let self = this;
        self.tagName = ''
        self.tagTypeVisible = true
    },
    // 编辑标签
    goEditTag(row) {
        let self = this;
        self.tag = row
        self.editTagTypeVisible = true
    },
    // 确认添加或编辑标签
    addAndEditTag(type) {
        let self = this
        let data = {
            guid: '',
            name: ''
        }
        if(type=='add') {
            data.name = self.tagName
        } else if(type=='edit') {
            data.name = self.tag.name
            data.guid = self.tag.guid 
        }
        self.$axios.post('/api/class-tag/saveOrUpdate',data,{
            headers: { token: self.userInfo.token }
        })
        .then(function() {
            self.$message({
                message: "操作成功",
                type: "success"
            });
            self.getTagList()
            self.tagTypeVisible = false
            self.editTagTypeVisible = false
        })
        .catch(function (error) {
          self.$message({
            message: "操作失败："+error.response.data.message,
            type: "warning",
          });
        });
    },
    // 删除
    onDelete(row) {
      let self = this;
      self
        .$confirm("确定删除 " + (row.name || "未命名") + "？")
        .then(() => {
          self.$axios
            .delete("/api/class-tag/delete", {
              data: { guid: row.guid },
              headers: { token: self.userInfo.token },
            })
            .then(() => {
              self.$message({
                message: "删除成功",
                type: "success",
              });
              self.getTagList()
            })
            .catch((error) => {
              self.$message({
                message: error.response.data.message,
                type: "warning",
              });
            });
        })
        .catch(() => {});
    }
  },
};
</script>
